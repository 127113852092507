<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="pa-3 mb-3">
          <v-toolbar>
            <v-toolbar-title>{{ this.paramDetail.rc_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <div class="pa-3">
            <v-card>
                <v-form ref="formSearch">
                    <v-row class="mx-1">
                        <v-col cols="12" md="12">
                            <v-form ref="mainForm">
                            <template v-for="(param,index) in  optionList">
                            <DlgReportParam :inputProp="param"  :key="'rptParam'+index" :content.sync="param['value']"></DlgReportParam>
                            <!-- {{param.type}} -->
                            </template>
                            </v-form>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-btn class="success" @click="generateReport"><v-icon>mdi-cog-clockwise</v-icon>Generate</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </div>
        <div class="pa-3">
            <v-card>
                <v-toolbar dark color="panel1">
                    <v-toolbar-title>Result</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <template v-if="chartList.chart">
                    <template v-if="paramDetail['rc_type'] == 'pie'"> 
                        <PieChart :chartProp="chartList.chart"></PieChart>
                    </template>
                    <template v-if="paramDetail['rc_type'] == 'bar'"> 
                        <BarChart :chartProp="chartList.chart"></BarChart>
                    </template>
                    <template v-if="paramDetail['rc_type'] == 'line'"> 
                        <LineChart :chartProp="chartList.chart"></LineChart>
                    </template>
                    <template v-if="paramDetail['rc_type'] == 'table'"> 
                        <TableChart :chartProp="chartList.chart"></TableChart>
                    </template>
                    <template v-if="paramDetail['rc_type'] == 'donut'"> 
                        <DonutChart :chartProp="chartList.chart"></DonutChart>
                    </template>

                </template>
            </v-card>


        </div>
    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApReportService from "@/services/ApReportService"
import DlgReportParam from '@/components/DlgReportParam'
import BarChart from '@/components/BarChart'
import PieChart from '@/components/PieChart'
import DonutChart from '@/components/DonutChart'
import LineChart from '@/components/LineChart'
import TableChart from '@/components/TableChart'


export default {
  components:{DlgMessage,DlgReportParam,BarChart,PieChart,LineChart,TableChart,DonutChart},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Report',disabled: false,href:'/',},
              {text:'View',disabled: false,href:'/setttings',},
              {text:'Details',disabled: false,href:'#',},
              ],
            paramDetail:[],
            optionList:[],
            chartList:[],
            selectedParam:[],
            
            valueRec:[],
            valueList:[],
            routeParam:[],
            form:{login_id:null,report_display_id:0,report_component_id:0},
            

    }
  },
  async created() {
    this.form.report_display_id = this.$route.params.report_display_id;
    this.form.report_component_id = this.$route.params.report_component_id;
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        let title = "Report"
        try{
            ApReportService.view_component(this.form).then((res) => {
                this.paramDetail = res.data.data;
                this.optionList = res.data.optionList;                 
                // console.log(this.optionList);
                // //console.log(this.form);
                // for (const [key, value] of Object.entries(this.form)) {
                //     if(value == null){
                //         this.form[key] = "";
                //     }
                // }                

               
            }).catch((e)=>{
                //console.log(e.response);
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            if(e.response)
                this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response);
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    
    async generateReport(){
        let title = "Report"
        let tmp = {};
        this.chartList = [];
        //console.log("optionlist",this.optionList);
        for(let index=0;index<this.optionList.length;index++){
            let key = this.optionList[index].name
            let value = this.optionList[index].value
            tmp[key] = value;
            this.selectedParam[index] = this.optionList[index].value;
            //console.log(index);
        }
        this.reqForm = Object.assign(this.form, tmp);
        //console.log("selectedParam",this.selectedParam.join());
        try{
            ApReportService.generate(this.reqForm).then((res) => {
                this.chartList = res.data.chartList;
                this.chartList.chart["title"] = this.paramDetail.rc_name
                this.chartList.chart["filterList"] = this.optionList;

                // if(this.selectedParam.join())
                //     this.chartList.chart["title"] = this.chartList.chart["title"] + " ("+this.selectedParam.join()+")";
                this.chartList.chart["description"] = this.paramDetail.rc_description;
                //console.log(res.data);
            }).catch((e)=>{
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error',title,e);
                
            })
            ;
        } catch(e) {
            this.loadingFlag = false
            this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
            
        }           
    },

  }
}
</script>