
<template>
    <v-layout>

    <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290" v-if="inputProp['type']=='date'" >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field  
                v-if="inputProp['type']=='date'"
                outlined
                :value="fieldContent"
                :label="inputProp['mandatory']?inputProp['alias']+' (*)':inputProp['alias']" 
                :readonly="inputProp['readonly']" 
                v-bind="attrs"
                v-on="on"
                :rules="inputProp['mandatory']?[rules.required]:[]"
            ></v-text-field>
        </template>
        <v-date-picker v-model="fieldContent" @change="dpicker.menu1 = false"></v-date-picker>
    </v-menu>

    <v-menu v-model="tpicker.menu1" :close-on-content-click="false" max-width="290"  v-if="inputProp['type']=='time'">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-if="inputProp['type']=='time'"
                outlined
                :value="fieldContent"
                :label="inputProp['mandatory']?inputProp['alias']+' (*)':inputProp['alias']" 
                :readonly="inputProp['readonly']" 
                v-bind="attrs"
                v-on="on"
                use-seconds
                :rules="inputProp['mandatory']?[rules.required]:[]"
            ></v-text-field>
        </template>
        <v-time-picker v-model="fieldContent" @change="tpicker.menu1 = false"
            use-seconds
            full-width
        >
        </v-time-picker>
            </v-menu>

        <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290" v-if="inputProp['type']=='yyyy-mm'" >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field  
                    v-if="inputProp['type']=='yyyy-mm'"
                    outlined
                    :value="fieldContent"
                    :label="inputProp['mandatory']?inputProp['alias']+' (*)':inputProp['alias']" 
                    :readonly="inputProp['readonly']" 
                    v-bind="attrs"
                    v-on="on"
                    :rules="inputProp['mandatory']?[rules.required]:[]"
                    prepend-icon="mdi-calendar"
                ></v-text-field>
            </template>
            <v-date-picker v-model="fieldContent" @change="dpicker.menu1=false" type="month" scrollable></v-date-picker>
        </v-menu>

    <v-select v-if="inputProp['type']=='yyyy'"
        :label="inputProp['mandatory']?inputProp['alias']+' (*)':inputProp['alias']" 
        outlined
        :items="yearList"
        item-text="description"
        item-value="code"
        v-model="fieldContent"
        :rules="inputProp['mandatory']?[rules.required]:[]">
    ></v-select>


    </v-layout>
</template>
    



<script>
export default {
    name: "DlgReportParam",
    props: {
        content: String,
        inputName:String,
        inputProp:Object
    },      
    components:{
    },
    computed: {
        fieldContent: {
        get() { return this.content},
        set(fieldContent) { this.$emit('update:content', fieldContent) }
        },

        yearList:{
            get() {
                let currYear = new Date().getFullYear();
                let yearArray = [];
                
                for(let i=0;i<10;i++){
                    let tmp = [];
                    tmp['code'] = (currYear - i).toString();
                    tmp['description'] = (currYear - i).toString();
                    yearArray.push(tmp);
                }
                return yearArray;
            }
        }
    },
        data() {
            return{
                showPassword:false,
                dpicker:{menu1: false,menu2: false,},
                tpicker:{menu1: false,menu2: false,},
                rules: {
                    required: value => !!value || 'Required.',
                    email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                    integer: value => /^[0-9]+$/.test(value)||'Number only',
                    decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                    pinNumber: value => value.length == 6 || 'require 6 digits number',
                    searchWord: value => value.length >= 3 || 'require 3 or more letters',
                    numberEmpty: value => {
                            if(value.trim().length > 0)
                                return /^[0-9]+$/.test(value)||'Number only';
                            else 
                                return true;
                        },
                    emailEmpty: value => {
                            if(value.trim().length > 0)
                                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address';
                            else
                                return true;
                        },                        
                    
                },
            }
        },
        created(){
          //console.log(this.inputProp[this.inputName]['type']);
        //   //console.log(this.inputName);
        //   //console.log(this.inputProp[this.inputName]);
        },


}    

</script>