var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[(_vm.inputProp['type']=='date')?_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.inputProp['type']=='date')?_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.fieldContent,"label":_vm.inputProp['mandatory']?_vm.inputProp['alias']+' (*)':_vm.inputProp['alias'],"readonly":_vm.inputProp['readonly'],"rules":_vm.inputProp['mandatory']?[_vm.rules.required]:[]}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,false,49829293),model:{value:(_vm.dpicker.menu1),callback:function ($$v) {_vm.$set(_vm.dpicker, "menu1", $$v)},expression:"dpicker.menu1"}},[_c('v-date-picker',{on:{"change":function($event){_vm.dpicker.menu1 = false}},model:{value:(_vm.fieldContent),callback:function ($$v) {_vm.fieldContent=$$v},expression:"fieldContent"}})],1):_vm._e(),(_vm.inputProp['type']=='time')?_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.inputProp['type']=='time')?_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.fieldContent,"label":_vm.inputProp['mandatory']?_vm.inputProp['alias']+' (*)':_vm.inputProp['alias'],"readonly":_vm.inputProp['readonly'],"use-seconds":"","rules":_vm.inputProp['mandatory']?[_vm.rules.required]:[]}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,false,3420104247),model:{value:(_vm.tpicker.menu1),callback:function ($$v) {_vm.$set(_vm.tpicker, "menu1", $$v)},expression:"tpicker.menu1"}},[_c('v-time-picker',{attrs:{"use-seconds":"","full-width":""},on:{"change":function($event){_vm.tpicker.menu1 = false}},model:{value:(_vm.fieldContent),callback:function ($$v) {_vm.fieldContent=$$v},expression:"fieldContent"}})],1):_vm._e(),(_vm.inputProp['type']=='yyyy-mm')?_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.inputProp['type']=='yyyy-mm')?_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","value":_vm.fieldContent,"label":_vm.inputProp['mandatory']?_vm.inputProp['alias']+' (*)':_vm.inputProp['alias'],"readonly":_vm.inputProp['readonly'],"rules":_vm.inputProp['mandatory']?[_vm.rules.required]:[],"prepend-icon":"mdi-calendar"}},'v-text-field',attrs,false),on)):_vm._e()]}}],null,false,1003520547),model:{value:(_vm.dpicker.menu1),callback:function ($$v) {_vm.$set(_vm.dpicker, "menu1", $$v)},expression:"dpicker.menu1"}},[_c('v-date-picker',{attrs:{"type":"month","scrollable":""},on:{"change":function($event){_vm.dpicker.menu1=false}},model:{value:(_vm.fieldContent),callback:function ($$v) {_vm.fieldContent=$$v},expression:"fieldContent"}})],1):_vm._e(),(_vm.inputProp['type']=='yyyy')?_c('v-select',{attrs:{"label":_vm.inputProp['mandatory']?_vm.inputProp['alias']+' (*)':_vm.inputProp['alias'],"outlined":"","items":_vm.yearList,"item-text":"description","item-value":"code","rules":_vm.inputProp['mandatory']?[_vm.rules.required]:[]},model:{value:(_vm.fieldContent),callback:function ($$v) {_vm.fieldContent=$$v},expression:"fieldContent"}},[_vm._v(" >")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }